<template>
  <div>
    <Stack class="dealer-size-matrix">
      <div class="dealer-size-matrix--sizes-tabs">
        <SizeTabSelector
          :active-size-group-index="activeSizeGroupIndex"
          :internal-size-groups="internalSizeGroups"
          @update:activeSizeGroupIndex="
            $emit('update:activeSizeGroupIndex', $event)
          "
        />
      </div>

      <form @submit.prevent="$emit('add-to-basket')">
        <div class="dealer-size-matrix-space-between">
          <div
            v-if="selectedSizeGroup"
            class="dealer-size-matrix__preferred-size"
          >
            {{ getDictionaryEntry("Common.Labels.PreferredSize") }}
            <span class="dealer-size-matrix__preferred-size-chart">
              ({{ getPreferredSizeChartLabel(selectedSizeGroup.sizes[0]) }})
            </span>
          </div>

          <div>
            <Button
              limited-width
              buy
              extra-small
              :disabled="disableBasketButton"
              :show-loader="isUpdatingBasket"
              type="submit"
            >
              {{ getDictionaryEntry("Product.AddToBasket") }}
            </Button>
          </div>
        </div>
        <div class="dealer-size-matrix__container">
          <ScrollableSizesMatrix
            :current-variant="currentVariant"
            :active-size-group-index="activeSizeGroupIndex"
            :is-updating-basket="isUpdatingBasket"
            :internal-size-groups="internalSizeGroups"
            :initial-internal-size-groups="initialInternalSizeGroups"
            :selected-size-group="selectedSizeGroup"
            @update:sizeQuantity="updateSizeQuantity($event)"
          />
        </div>
      </form>
    </Stack>
  </div>
</template>

<script>
import Stack from "@/components/Stack";
import ScrollableSizesMatrix from "@/components/ScrollableSizesMatrix.vue";
import SizeTabSelector from "@/components/SizeTabSelector.vue";
import Button from "@/components/Button.vue";

export default {
  name: "DealerSizeMatrix",
  components: {
    Button,
    SizeTabSelector,
    ScrollableSizesMatrix,
    Stack,
  },
  props: {
    currentVariant: {
      required: true,
      type: Object,
    },
    sizeGroups: {
      required: true,
      type: Array,
    },
    activeSizeGroupIndex: {
      default: 0,
      required: true,
      type: Number,
    },
    isUpdatingBasket: {
      default: false,
      type: Boolean,
    },
    internalSizeGroups: {
      required: true,
      type: Array,
    },
    initialInternalSizeGroups: {
      required: true,
      type: Array,
    },
    disableBasketButton: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    selectedSizeGroup() {
      return this.internalSizeGroups[this.activeSizeGroupIndex];
    },
  },
  methods: {
    updateSizeQuantity({ index, quantity }) {
      this.$emit("update:sizeQuantity", {
        index,
        quantity,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-size-matrix {
  &-space-between {
    display: flex;
    justify-content: space-between;
  }

  &--sizes-tabs {
    display: none;
    @media (min-width: 64rem) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__preferred-size {
    color: var(--color-black);
    font-family: var(--font-family-roboto);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 0.875rem */
    margin: 1rem 0;

    &-chart {
      text-transform: uppercase;
    }
  }

  &__container {
    display: flex;
    position: relative;
    flex-grow: 1;
  }
}
</style>
