import { errorAlert } from "@/helpers/errorAlert";
import { alertTypes } from "@/helpers/alertTypes";
import { mapActions } from "vuex";
import differenceWith from "lodash.differencewith";
import isEqual from "lodash.isequal";

export default {
  data() {
    return {
      isUpdatingBasket: false,
    };
  },
  methods: {
    ...mapActions("alerts", ["OPEN_ALERT"]),
    ...mapActions("basket", ["GET_BASKET", "UPDATE_BASKET"]),
    /**
     *Function to bulk update sizes in basket
     * @param {Array} sizes
     */
    /**
     * Function to bulk update sizes in the basket.
     * This function sets the `isUpdatingBasket` flag to true, then maps over the `sizes` array to create
     * an array of promises that call the `UPDATE_BASKET` action for each size. It uses `Promise.allSettled`
     * to wait for all promises to settle (either fulfilled or rejected).
     *
     * @param {Array} sizes - An array of size objects to be updated in the basket.
     */
    bulkUpdateBasket(sizes) {
      this.isUpdatingBasket = true;

      // Create an array of promises to update each size in the basket
      const promises = sizes.map(size => {
        return this.UPDATE_BASKET({ ...size });
      });

      // Wait for all promises to settle
      Promise.allSettled(promises)
        .then(data => {
          this.isUpdatingBasket = false;

          // Filter out the rejected and fulfilled promises
          const rejectedData = data.filter(
            ({ status }) => status === "rejected",
          );
          const fullfilledData = data.filter(
            ({ status }) => status === "fulfilled",
          );

          // Handle rejected promises by showing error alerts
          if (rejectedData.length) {
            rejectedData.forEach(({ reason }) => {
              errorAlert(reason);
            });
          }

          // Handle fulfilled promises by showing a success alert
          if (fullfilledData.length) {
            this.OPEN_ALERT({
              key: "SuccessMessage.BasketUpdated",
              type: alertTypes.SUCCESS,
            });
          }

          this.GET_BASKET();
        })
        .catch(error => {
          this.isUpdatingBasket = false;

          // Handle any errors that occur during the update process
          errorAlert(error);
        });
    },
    /**
     * Update the size in the basket at the specified index.
     * This function sets the `isUpdatingBasket` flag to true, then calls the `UPDATE_BASKET` action for the size at the given index.
     * If there are more sizes to update, it recursively calls itself with the next index.
     * Once all sizes are updated, it fetches the updated basket and shows a success alert.
     *
     * @param {number} index - The index of the size to update in the array.
     * @param {Array} array - The array of sizes to be updated.
     */
    updateSize(index, array) {
      this.isUpdatingBasket = true;

      this.UPDATE_BASKET({ ...array[index] })
        .then(() => {
          if (index < array.length - 1) {
            this.updateSize(index + 1, array);
            return;
          }

          this.isUpdatingBasket = false;
          if (index === array.length - 1) {
            this.GET_BASKET();
            this.OPEN_ALERT({
              key: "SuccessMessage.BasketUpdated",
              type: alertTypes.SUCCESS,
            });
          }
        })
        .catch(error => {
          this.isUpdatingBasket = false;
          errorAlert(error);
        });
    },
    /**
     * Get the sizes that have changed.
     * This function compares the current sizes with the initial sizes and returns an array of sizes that have changed.
     *
     * @param {Array} internalSizeGroups - The current internal size groups.
     * @param {Array} initialInternalSizeGroups - The initial internal size groups.
     * @returns {Array} An array of sizes that have changed.
     */
    getChangedSizes(internalSizeGroups, initialInternalSizeGroups) {
      const currentSizes = internalSizeGroups.map(group => group.sizes).flat();
      const initialSizes = initialInternalSizeGroups
        .map(group => group.sizes)
        .flat();

      // Find the sizes that have changed
      return differenceWith(currentSizes, initialSizes, isEqual);
    },
    /**
     * Get the sizes that have changed along with their previous quantities.
     * This function compares the current sizes with the initial sizes and returns an array of objects
     * containing the SKU, current quantity, and previous quantity for each size that has changed.
     *
     * @param {Array} internalSizeGroups - The current internal size groups.
     * @param {Array} initialInternalSizeGroups - The initial internal size groups.
     * @param {Boolean} removeNoStock
     * @returns {Array} An array of objects, each containing the SKU, current quantity, and previous quantity of a changed size.
     */
    getChangedSizesWithPreviousQuantity(
      internalSizeGroups,
      initialInternalSizeGroups,
      removeNoStock = false,
    ) {
      const initialSizes = initialInternalSizeGroups
        .map(group => group.sizes)
        .flat();

      const changedSizes = this.getChangedSizes(
        internalSizeGroups,
        initialInternalSizeGroups,
      );

      // Map the changed sizes to include their previous quantities
      return changedSizes
        .filter(c => {
          if (!removeNoStock) {
            return true;
          }
          return !(
            c.inventory.level === "red" &&
            !c.inventory.restockDate &&
            c.inventory.stockMessage
          );
        })
        .map(({ quantity, sku }) => {
          const matchingInitialSize = initialSizes.find(
            size => size.sku === sku,
          );

          return {
            previousQuantity: matchingInitialSize
              ? matchingInitialSize.quantity || 0
              : 0,
            quantity: quantity || 0,
            sku,
          };
        });
    },
    productHasNoExpectedRestockDate(size) {
      return (
        !size.inventory.isExternalProduct &&
        size.inventory.level === "red" &&
        !size.inventory.restockDate &&
        size.inventory.stockMessage
      );
    },
    getSizesWithChanges(iternalSizeGroups, initialInternalSizeGroups) {
      return this.getChangedSizes(iternalSizeGroups, initialInternalSizeGroups)
        .filter(s => this.productHasNoExpectedRestockDate(s))
        .map(s => {
          return {
            size: this.getPreferredSizeChartValue(s),
            quantity: s.quantity || 0,
            sku: s.sku,
          };
        });
    },
  },
};
