var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.employees)?_c('div',{staticClass:"employees"},[_c('button',{staticClass:"employees__toggle",class:{
      'employees__toggle--open': _vm.isOpen,
    },on:{"click":_vm.toggle}},[_c('h2',{staticClass:"employees__title"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Common.Labels.EmployeesBeingShoppedTo"))+" ")]),_c('svg',{staticClass:"employees__toggle-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"m6 9 6 6 6-6"}})])]),_c('ol',{ref:"content",staticClass:"employees__list"},_vm._l((_vm.internalEmployees),function(employee){return _c('li',{key:employee.id,staticClass:"employees__item"},[_c('div',{staticClass:"employee"},[_c('div',{staticClass:"employee__block"},[_c('h3',{staticClass:"employee__name"},[_vm._v(" "+_vm._s(employee.name)+" ")]),_c('div',{staticClass:"employee__information"},[(employee.number)?_c('span',[_vm._v(_vm._s(employee.number))]):_vm._e(),(employee.number && employee.groupName)?_c('span',[_vm._v(" - ")]):_vm._e(),(employee.groupName)?_c('span',[_vm._v(_vm._s(employee.groupName))]):_vm._e(),(_vm.shouldUseWallet)?_c('span',{staticClass:"remaining-balance",class:{
                'remaining-balance__error': !_vm.hasRemainingBalance(employee.id),
              }},[_vm._v(" "+_vm._s(_vm.getUserRemainingBalance(employee.id)))]):_vm._e()])]),_c('div',{staticClass:"employee__block"},[_c('ul',{staticClass:"sizes"},_vm._l((employee.sizesWithQuantities),function({
                basketLineId,
                isUpdating,
                quantity,
                size,
              }){return _c('li',{key:basketLineId,staticClass:"size"},[_c('Input',{staticClass:"size__input",attrs:{"center-text":true,"hide-label":true,"label":_vm.getDictionaryEntry('Common.Labels.Quantity'),"loading":isUpdating,"value":quantity,"min":"0","step":"1","type":"number"},on:{"blur":function($event){return _vm.handleBlur($event, basketLineId)},"input":function($event){return _vm.handleInput($event, basketLineId)}}}),_c('span',{ref:"description",refInFor:true,staticClass:"size__description"},[_vm._v(_vm._s(_vm.getDictionaryEntry("Common.Labels.SizeAbbreviation"))+" "+_vm._s(size))])],1)}),0)])])])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }