<template>
  <div class="quick-buy">
    <Modal
      :title="
        currentProduct && currentVariant
          ? `${currentProduct.name} (${currentVariant.id})`
          : ''
      "
      :visible="true"
      @close="handleClose"
    >
      <template v-if="isUserDealer" #header>
        <CustomerSelector from-quick-buy />
      </template>
      <template #body>
        <DynamicData :loading="isLoading" transition="fade">
          <Stack :half="true">
            <div
              v-if="currentProduct && currentVariant"
              class="quick-buy__container"
            >
              <DealerProductCard
                :active-size-group-index="activeSizeGroupIndex"
                :current-variant="currentVariant"
                :internal-size-groups="internalSizeGroups"
                :product="currentProduct"
                quick-buy-mode
                @update:activeSizeGroupIndex="activeSizeGroupIndex = $event"
              />
              <div class="quick-buy__container_matrix">
                <DealerSizeMatrix
                  v-if="currentProduct && currentVariant"
                  :size-groups="currentVariant?.sizeGroups"
                  :current-variant="currentVariant"
                  :is-updating-basket="isUpdatingBasket"
                  :internal-size-groups="internalSizeGroups"
                  :initial-internal-size-groups="initialInternalSizeGroups"
                  :active-size-group-index="activeSizeGroupIndex"
                  :disable-basket-button="!basketHasChanged"
                  @update:activeSizeGroupIndex="activeSizeGroupIndex = $event"
                  @update:sizeQuantity="updateSizeQuantity($event)"
                  @add-to-basket="handleAddToBasket"
                />
              </div>
            </div>
          </Stack>
          <Stack :double="true" class="quick-buy__footer">
            <div class="quick-buy__footer-block">
              <SubstituteProducts />
            </div>
            <div class="quick-buy__footer-block">
              <Stack>
                <PriceSummary
                  :currency="totalPrice.currency"
                  :discount="totalPrice.amountOfDiscount"
                  :quantity="totalNumberOfItems"
                  :subtotal="totalPrice.normalPrice"
                  :total="totalPrice.price"
                />
              </Stack>
            </div>
          </Stack>
        </DynamicData>
      </template>
    </Modal>
    <Modal
      v-if="showStockWarning"
      :title="getDictionaryEntry('Product.NoExpectedStockWarningTitle')"
      :visible="showStockWarning"
      @close="handleCloseWarningModal"
    >
      <template #body>
        <NoExpectStockWarningContent
          :products="noExpectedRestockDateAddedProducts"
        />
      </template>
      <template #footer>
        <Button limited-width secondary @click="handleNo">
          {{ getDictionaryEntry("Product.NoExpectedStockWarningNo") }}
        </Button>
        <Button limited-width @click="handleYes">
          {{ getDictionaryEntry("Product.NoExpectedStockWarningYes") }}
        </Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomerSelector from "@/components/CustomerSelector";
import DynamicData from "@/components/DynamicData";
import Modal from "@/components/Modal";
import Stack from "@/components/Stack";
import { statuses } from "@/helpers/statuses";
import DealerSizeMatrix from "@/components/DealerSizeMatrix.vue";
import cloneDeep from "lodash.clonedeep";
import PriceSummary from "@/components/PriceSummary.vue";
import SubstituteProducts from "@/components/SubstituteProducts.vue";
import DealerProductCard from "@/components/DealerProductCard.vue";
import basket from "@/mixins/basket";
import Button from "@/components/Button.vue";
import NoExpectStockWarningContent from "@/components/NoExpectStockWarningContent.vue";

export default {
  name: "QuickBuy",
  components: {
    NoExpectStockWarningContent,
    Button,
    DealerProductCard,
    SubstituteProducts,
    PriceSummary,
    DealerSizeMatrix,
    CustomerSelector,
    DynamicData,
    Modal,
    Stack,
  },
  mixins: [basket],
  data() {
    return {
      initialInternalSizeGroups: [],
      internalSizeGroups: [],
      activeSizeGroupIndex: 0,
      showStockWarning: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["customerId", "isUserDealer"]),
    ...mapGetters("products", [
      "currentProduct",
      "currentVariant",
      "statusOfProducts",
    ]),
    ...mapGetters("basket", [
      "basketLineQuantity",
      "basketLineQuantityForCustomer",
      "basketLines",
      "selectedCustomerForAddingToBasket",
    ]),
    isLoading() {
      return this.statusOfProducts === statuses.LOADING;
    },
    totalNumberOfItems() {
      let quantity = 0;

      this.internalSizeGroups.forEach(
        sizeGroup => (quantity += this.getTotalOfGroup(sizeGroup)),
      );

      return quantity;
    },
    totalPrice() {
      const { convertNumberToLocaleString, internalSizeGroups } = this;

      let totalAmountOfDiscount = 0;
      let totalCurrency = "";
      let totalIsDiscounted = false;
      let totalNormalPrice = 0;
      let totalPrice = 0;

      const sizes = internalSizeGroups.map(group => group.sizes).flat();

      sizes.forEach(size => {
        const {
          price: { currency, isDiscounted, normalPriceValue, priceValue },
          quantity,
        } = size;

        if (!totalCurrency) totalCurrency = currency;
        if (!quantity) return;
        if (!totalIsDiscounted) totalIsDiscounted = isDiscounted;

        totalNormalPrice += normalPriceValue * quantity;
        totalPrice += priceValue * quantity;
        totalAmountOfDiscount = totalNormalPrice - totalPrice;
      });

      return {
        amountOfDiscount: totalAmountOfDiscount
          ? convertNumberToLocaleString(totalAmountOfDiscount)
          : null,
        currency: totalCurrency,
        isDiscounted: totalIsDiscounted,
        normalPrice: convertNumberToLocaleString(totalNormalPrice),
        price: convertNumberToLocaleString(totalPrice),
      };
    },
    basketHasChanged() {
      return (
        this.getChangedSizesWithPreviousQuantity(
          this.internalSizeGroups,
          this.initialInternalSizeGroups,
        ).length > 0
      );
    },
    noExpectedRestockDateAddedProducts() {
      return this.getSizesWithChanges(
        this.internalSizeGroups,
        this.initialInternalSizeGroups,
      );
    },
  },
  watch: {
    basketLines: {
      handler: function() {
        this.updateSizeGroups();
      },
      deep: true,
    },
    currentVariant() {
      this.updateSizeGroups();
    },
  },
  created() {
    const {
      $route: {
        query: { mode, productId, variantId },
      },
      GET_PRODUCT,
      UPDATE_CURRENT_PRODUCT,
      UPDATE_CURRENT_VARIANT_ID,
      isUserDealer,
    } = this;

    if (mode && mode.includes("details")) return;

    UPDATE_CURRENT_PRODUCT({ product: {} });
    UPDATE_CURRENT_VARIANT_ID({ id: variantId });
    this.updateSizeGroups();

    if (!isUserDealer) {
      console.log("User is not a dealer");
      GET_PRODUCT({ id: productId });
    }
  },
  destroyed() {
    const {
      $route: {
        query: { mode },
      },
      UPDATE_CURRENT_PRODUCT,
    } = this;

    if (mode && mode.includes("details")) return;

    UPDATE_CURRENT_PRODUCT({ product: null });
  },
  methods: {
    ...mapActions("alerts", ["OPEN_ALERT"]),
    ...mapActions("basket", ["GET_BASKET", "UPDATE_BASKET"]),
    ...mapActions("products", [
      "GET_PRODUCT",
      "UPDATE_CURRENT_PRODUCT",
      "UPDATE_CURRENT_VARIANT_ID",
    ]),
    handleClose() {
      const {
        $route: { query },
        $router,
      } = this;

      if (query.mode && query.mode.includes("details")) {
        $router.push({
          query: {
            ...query,
            mode: "details",
            productId: query.productId,
            variantId: query.variantId,
          },
        });
        return;
      }

      const {
        mode, //eslint-disable-line
        customerId, //eslint-disable-line
        productId, //eslint-disable-line
        variantId, //eslint-disable-line
        ...remainingQueryParams
      } = query;

      $router.push({ query: remainingQueryParams });
    },
    handleAddToBasket() {
      const changedSize = this.getChangedSizes(
        this.internalSizeGroups,
        this.initialInternalSizeGroups,
      );

      const changedSizeWithQuantity = changedSize.filter(
        size => size.quantity > 0,
      );

      if (
        changedSizeWithQuantity.some(size =>
          this.productHasNoExpectedRestockDate(size),
        )
      ) {
        this.showStockWarning = true;
        return;
      }

      this.updateBasket();
    },
    updateSizeGroups() {
      if (!this.currentVariant?.sizeGroups) {
        return;
      }

      const sizeGroups = cloneDeep(this.currentVariant.sizeGroups);

      sizeGroups.forEach(group => {
        group.sizes.map(size => {
          size.quantity = this.getQuantityOfSize(size.sku) || "";
          return size;
        });
      });

      this.initialInternalSizeGroups = sizeGroups;
      this.internalSizeGroups = cloneDeep(sizeGroups);
    },
    getQuantityOfSize(sku) {
      if (!sku) return 0;

      if (this.selectedCustomerForAddingToBasket) {
        return this.basketLineQuantityForCustomer({
          customerId: this.selectedCustomerForAddingToBasket,
          sku,
        });
      }

      return this.basketLineQuantity(sku);
    },
    updateSizeQuantity({ index, quantity }) {
      this.internalSizeGroups[this.activeSizeGroupIndex].sizes[
        index
      ].quantity = quantity;
    },
    getTotalOfGroup(group) {
      if (!group || !group.sizes || !group.sizes.length) return 0;

      let totalQuantity = 0;

      group.sizes.forEach(size => {
        totalQuantity += size.quantity || 0;
      });

      return totalQuantity;
    },
    updateBasket(removeNoStock = false) {
      const changedSizesWithPreviousQuantity = this.getChangedSizesWithPreviousQuantity(
        this.internalSizeGroups,
        this.initialInternalSizeGroups,
        removeNoStock,
      );

      if (changedSizesWithPreviousQuantity.length === 0) {
        this.GET_BASKET();
        return;
      }

      this.updateSize(0, changedSizesWithPreviousQuantity);
    },

    handleCloseWarningModal() {
      this.showStockWarning = false;
    },
    handleNo() {
      this.handleCloseWarningModal();
      this.updateBasket(true);
    },
    handleYes() {
      this.handleCloseWarningModal();
      this.updateBasket();
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-buy {
  &__checkout {
    @include reset-button();

    align-items: center;
    background-color: var(--color-button-secondary-background);
    border: 1px solid var(--color-button-secondary-border);
    border-radius: 2rem;
    color: var(--color-button-secondary-text);
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    justify-content: center;
    padding: var(--spacing-button);
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: background-color 300ms ease, border-color 300ms ease,
      box-shadow 300ms ease, color 300ms ease, opacity 300ms ease;

    @media (min-width: 64rem) {
      display: none;
    }

    &:focus,
    &:hover {
      border-color: var(--color-button-secondary-border-active);
    }

    .checkout-view & {
      display: none;
    }
  }

  &__container {
    display: flex;
    height: 100%;
    gap: 1.5rem;
    flex-direction: column;

    @media (min-width: 64rem) {
      flex-direction: row;
    }

    &_matrix {
      flex: 1 1 auto;
    }
  }

  &__list {
    @media (min-width: 48rem) {
      display: none;
    }
  }

  &__matrix {
    display: none;

    @media (min-width: 48rem) {
      display: block;
    }
  }

  &__size {
    font-size: 1.125rem;
    font-weight: 700;

    &-chart {
      text-transform: uppercase;
    }
  }

  &__update {
    display: none;

    @media (min-width: 48rem) {
      display: block;
    }

    &-buttons {
      display: flex;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    &-block {
      min-width: 0;

      &:first-child {
        flex: 1 1 auto;
        margin-right: var(--spacing-stack);

        @media (min-width: 64rem) {
          margin-right: calc(var(--spacing-stack) * 2);
        }

        @media (min-width: 120rem) {
          margin-right: 17.5rem;
        }
      }
    }

    &:last-child {
      flex: 0 0 auto;
    }
  }
}
</style>
