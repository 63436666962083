<template>
  <Stack>
    <p class="message">
      {{ getDictionaryEntry("Product.NoExpectedStockWarningMessage") }}
    </p>

    <ul>
      <li v-for="size in products" :key="size.sku" class="size">
        {{ size.size }}
      </li>
    </ul>
  </Stack>
</template>
<script>
import Stack from "@/components/Stack.vue";

export default {
  name: "NoExpectStockWarningContent",
  components: { Stack },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  white-space: pre-line;
}

.size {
  color: var(--color-matrix-title-text);
  font-weight: 700;
}
</style>
