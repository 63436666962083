import axios from "axios";
import router from "@/router";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { alertTypes } from "@/helpers/alertTypes";
import { pushToDataLayer } from "@/helpers/tracking";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  basketLines: [],
  errors: null,
  id: null,
  isModalForBasketErrorsOpen: false,
  namesForPrint: [],
  selectedCustomerForAddingToBasket: null,
  shouldAccountForPrintedNames: false,
  status: "",
  totalPrice: null,
  currency: null,
});

const getters = {
  basketLineQuantity: state => sku => {
    const matchingBasketLine = state.basketLines.find(
      orderLine => orderLine.sku === sku,
    );

    if (!matchingBasketLine) return 0;

    return matchingBasketLine.quantity || 0;
  },
  basketLineQuantityForCustomer: state => ({ customerId, sku }) => {
    const matchingBasketLines = state.basketLines
      .filter(basketLine => basketLine.sku === sku)
      .filter(basketLine => basketLine.shopToCustomerId === customerId);

    if (!matchingBasketLines) return 0;

    return matchingBasketLines
      .map(orderLine => orderLine.quantity)
      .reduce((sum, current) => sum + current, 0);
  },
  basketLines: state => state.basketLines,
  basketCurrency: state => state.currency,
  errors: state => state.errors,
  id: state => state.id,
  isModalForBasketErrorsOpen: state => state.isModalForBasketErrorsOpen,
  isTotalPriceGreaterThanWalletBalance: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => {
    const balance = rootGetters["wallets/balance"];
    const { totalPrice } = state;

    if (!totalPrice || !totalPrice.price || !balance || !balance.amount) {
      return false;
    }

    return totalPrice.priceValue > balance.amount;
  },
  namesForPrint: state => state.namesForPrint,
  numberOfItemsInBasket: state =>
    state.basketLines
      .filter(orderLine => orderLine.includeInQuantity)
      .map(orderLine => orderLine.quantity)
      .reduce((sum, current) => sum + current, 0),
  selectedCustomerForAddingToBasket: state =>
    state.selectedCustomerForAddingToBasket,
  selectedUserForAddingToBasket: state => state.selectedUserForAddingToBasket,
  shouldAccountForPrintedNames: state => state.shouldAccountForPrintedNames,
  statusOfBasket: state => state.status,
  totalPrice: state => state.totalPrice,
};

const actions = {
  ADD_BASKET_LINE({ commit, dispatch, rootGetters }, payload) {
    commit("SET_BASKET_STATUS", { status: statuses.LOADING });

    const userConfiguration = rootGetters["authentication/userConfiguration"];
    const userId = rootGetters["authentication/userId"];

    dispatch("inventory/GET_INVENTORY", { sku: payload.sku }, { root: true });

    return axios
      .post(
        `${endpoints.ECOMMERCE}/basket-management/users/${userId}/basket/product`,
        {
          quantity: 1,
          sku: payload.sku,
          userId,
        },
      )
      .then(({ data }) => {
        commit("SET_BASKET_STATUS", { status: statuses.SUCCESS });
        commit("SET_BASKET_LINES", { basketLines: data.lines });
        commit("SET_SHOULD_ACCOUNT_FOR_PRINTED_NAMES", {
          value: data.containsPrintedName,
        });
        commit("SET_TOTAL_PRICE", { totalPrice: data.totalPrice });

        const {
          productName,
          id,
          unitPrice: { price },
          variantName,
        } = data.lines.find(line => line.sku === payload.sku);

        pushToDataLayer({
          event: "addToCart",
          ecommerce: {
            currencyCode: userConfiguration.contextCurrency,
            add: {
              products: [
                {
                  name: productName,
                  id,
                  price,
                  variant: variantName,
                  quantity: 1,
                },
              ],
            },
          },
        });

        dispatch(
          "alerts/OPEN_ALERT",
          {
            data: {
              productName,
            },
            key: "SuccessMessage.ProductAdded",
            type: alertTypes.SUCCESS,
          },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_BASKET_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  CLOSE_MODAL_FOR_BASKET_ERRORS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_BASKET_ERRORS", { value: false });
  },
  CONFIRM_BASKET({ commit, dispatch, getters, rootGetters }, payload) {
    commit("SET_BASKET_STATUS", { status: statuses.CONFIRMING });

    const userId = rootGetters["authentication/userId"];

    const {
      comment,
      deliveryAddress,
      email,
      parcelShopId,
      reference,
      requisitionNumber,
    } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/basket-management/users/${userId}/confirm`,
        {
          comment,
          deliveryAddress,
          parcelShopId,
          reference,
          requisitionNumber,
          userEmail: email,
        },
      )
      .then(({ data }) => {
        const userId = rootGetters["authentication/userId"];

        dispatch(
          "wallets/GET_BALANCE_OF_WALLET",
          { id: userId },
          { root: true },
        );

        //Due to the orders being created as a scheduled job we can't rely on using the
        //actual order id since it can take up a minute until creation is complete.
        pushToDataLayer({
          ecommerce: {
            purchase: {
              actionField: {
                id: getters.id,
                affiliation: "Online store",
                revenue: getters.totalPrice.price,
              },
            },
            products: getters.basketLines.map(basketLine => {
              const {
                productName,
                id,
                quantity,
                unitPrice: { price },
                variantName,
              } = basketLine;

              return {
                name: productName,
                id,
                price,
                variant: variantName,
                quantity,
              };
            }),
          },
        });

        router.push({
          name: "orderReceipt",
          params: {
            orderNumber: data.orderNumber,
          },
        });

        commit("SET_BASKET_STATUS", { status: statuses.SUCCESS });

        dispatch("shopAsUser/RESET_SHOP_AS", {}, { root: true });
        dispatch("authentication/GET_USER_CONFIGURATION", {}, { root: true });

        commit("SET_BASKET_LINES", { basketLines: [] });
      })
      .catch(error => {
        commit("SET_BASKET_STATUS", { status: statuses.FAILURE });

        if (error.response && error.response.status === 409) {
          commit("SET_BASKET_ERRORS", {
            errors: error.response.data,
          });
          dispatch("OPEN_MODAL_FOR_BASKET_ERRORS");
          return;
        }

        errorAlert(error);
      });
  },
  GET_BASKET({ commit, dispatch, rootGetters }) {
    commit("SET_BASKET_STATUS", { status: statuses.LOADING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .get(`${endpoints.ECOMMERCE}/basket-management/users/${userId}/basket`)
      .then(
        ({
          data: { containsPrintedName, id, lines, totalPrice, currency },
        }) => {
          commit("SET_BASKET_STATUS", { status: statuses.SUCCESS });
          commit("SET_BASKET_ERRORS", { errors: null });
          commit("SET_BASKET_ID", { id });
          commit("SET_BASKET_LINES", { basketLines: lines });
          commit("SET_SHOULD_ACCOUNT_FOR_PRINTED_NAMES", {
            value: containsPrintedName,
          });
          commit("SET_TOTAL_PRICE", { totalPrice });
          commit("SET_CURRENCY", { currency });

          dispatch(
            "inventory/GET_INVENTORY_LIST",
            { list: lines.map(line => line.sku) },
            { root: true },
          );
        },
      )
      .catch(error => {
        commit("SET_BASKET_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_NAMES_FOR_PRINT({ commit, rootGetters }) {
    commit("SET_BASKET_STATUS", { status: statuses.LOADING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .get(
        `${endpoints.ECOMMERCE}/basket-management/users/${userId}/basket/printed-name-list`,
      )
      .then(({ data }) => {
        commit("SET_BASKET_STATUS", { status: statuses.SUCCESS });
        commit("SET_NAMES_FOR_PRINT", { names: data.items });
      })
      .catch(error => {
        commit("SET_BASKET_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_BASKET_ERRORS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_BASKET_ERRORS", { value: true });
  },
  REMOVE_VARIANT_FROM_BASKET({ commit, dispatch, rootGetters }, payload) {
    commit("SET_BASKET_STATUS", { status: statuses.LOADING });

    const userConfiguration = rootGetters["authentication/userConfiguration"];
    const userId = rootGetters["authentication/userId"];

    const {
      id,
      name,
      productId,
      productName,
      totalLinePrice,
      totalPrice,
      variantId,
      variantName,
    } = payload.basketLine;

    const product = {
      name: productName,
      id: productId,
      price: totalLinePrice ? totalLinePrice.price : totalPrice,
      variant: variantName || name,
    };

    axios
      .post(
        `${endpoints.ECOMMERCE}/basket-management/users/${userId}/remove-variant/`,
        {
          shopToCustomerId: payload.customerId || null,
          variantId: variantId || id,
        },
      )
      .then(({ data }) => {
        commit("SET_BASKET_STATUS", { status: statuses.SUCCESS });
        commit("SET_BASKET_LINES", { basketLines: data.lines });
        commit("SET_SHOULD_ACCOUNT_FOR_PRINTED_NAMES", {
          value: data.containsPrintedName,
        });
        commit("SET_TOTAL_PRICE", { totalPrice: data.totalPrice });

        pushToDataLayer({
          event: "removeFromCart",
          ecommerce: {
            currencyCode: userConfiguration.contextCurrency,
            remove: {
              products: [{ ...product }],
            },
          },
        });

        dispatch(
          "alerts/OPEN_ALERT",
          {
            data: {
              productName,
            },
            key: "SuccessMessage.ProductRemoved",
            type: alertTypes.SUCCESS,
          },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_BASKET_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_BASKET({ dispatch, getters, rootGetters }, payload) {
    const { previousQuantity, quantity, sku } = payload;
    let shopToUserId = payload.shopToUserId;

    const shopToCustomerId = shopToUserId
      ? null
      : getters.selectedCustomerForAddingToBasket ||
        rootGetters["authentication/customerId"];
    const userConfiguration = rootGetters["authentication/userConfiguration"];
    const userId = rootGetters["authentication/userId"];

    dispatch("inventory/GET_INVENTORY", { sku }, { root: true });

    if (!shopToUserId && !shopToCustomerId) {
      const shopAs = rootGetters["shopAsUser/shopAs"];
      console.error("No customer or user ID was found on Update Basket.");
      shopToUserId =
        !!shopAs?.onBehalfOfUser && !!userConfiguration?.onBehalfUserName
          ? shopAs.onBehalfOfUser
          : userConfiguration.userId;
    }

    if (!previousQuantity) {
      return axios
        .post(
          `${endpoints.ECOMMERCE}/basket-management/users/${userId}/basket/product`,
          {
            quantity,
            shopToCustomerId,
            shopToUserId,
            sku,
          },
        )
        .then(({ data }) => {
          const {
            productName,
            id,
            unitPrice: { price },
            variantName,
          } = data.lines.find(line => line.sku === payload.sku);

          pushToDataLayer({
            event: "addToCart",
            ecommerce: {
              currencyCode: userConfiguration.contextCurrency,
              add: {
                products: [
                  {
                    name: productName,
                    id,
                    price,
                    variant: variantName,
                    quantity: 1,
                  },
                ],
              },
            },
          });
          return data;
        });
    } else {
      return axios
        .patch(
          `${endpoints.ECOMMERCE}/basket-management/users/${userId}/product`,
          {
            quantity,
            shopToCustomerId,
            shopToUserId,
            sku,
          },
        )
        .then(({ data }) => {
          let matchingOrderLine;

          if (quantity) {
            matchingOrderLine = data.lines.find(line => line.sku === sku);
            if (!matchingOrderLine) {
              console.error("No matching SKU was found.");
              return;
            }

            const {
              productName,
              id,
              unitPrice: { price },
              variantName,
            } = matchingOrderLine;

            const product = {
              name: productName,
              id,
              price,
              variant: variantName,
            };

            if (previousQuantity > quantity) {
              pushToDataLayer({
                event: "removeFromCart",
                ecommerce: {
                  currencyCode: userConfiguration.contextCurrency,
                  remove: {
                    products: [
                      {
                        ...product,
                        quantity: quantity
                          ? previousQuantity - quantity
                          : quantity,
                      },
                    ],
                  },
                },
              });
            } else {
              pushToDataLayer({
                event: "addToCart",
                ecommerce: {
                  currencyCode: userConfiguration.contextCurrency,
                  add: {
                    products: [
                      { ...product, quantity: quantity - previousQuantity },
                    ],
                  },
                },
              });
            }
          } else {
            matchingOrderLine = getters.basketLines.find(
              line => line.sku === sku,
            );
            if (!matchingOrderLine) throw Error("No matching SKU was found.");

            const {
              productName,
              id,
              unitPrice: { price },
              variantName,
            } = matchingOrderLine;

            pushToDataLayer({
              event: "removeFromCart",
              ecommerce: {
                currencyCode: userConfiguration.contextCurrency,
                remove: {
                  products: [
                    {
                      name: productName,
                      id,
                      price,
                      variant: variantName,
                      quantity: previousQuantity,
                    },
                  ],
                },
              },
            });
          }
          return data;
        });
    }
  },
  UPDATE_BASKET_LINE_QUANTITY(
    { commit, dispatch, getters, rootGetters },
    payload,
  ) {
    commit("SET_BASKET_STATUS", { status: statuses.LOADING });

    const { previousQuantity, quantity, sku } = payload;
    let shopToUserId = payload.shopToUserId;
    const userConfiguration = rootGetters["authentication/userConfiguration"];

    const userId = rootGetters["authentication/userId"];

    dispatch("inventory/GET_INVENTORY", { sku }, { root: true });

    if (!shopToUserId) {
      const shopAs = rootGetters["shopAsUser/shopAs"];
      console.error("No customer or user ID was found on Update Basket Line.");
      shopToUserId =
        !!shopAs?.onBehalfOfUser && !!userConfiguration?.onBehalfUserName
          ? shopAs.onBehalfOfUser
          : userConfiguration.userId;
    }

    return axios
      .patch(
        `${endpoints.ECOMMERCE}/basket-management/users/${userId}/product`,
        {
          quantity,
          sku,
          shopToUserId,
        },
      )
      .then(({ data }) => {
        commit("SET_BASKET_STATUS", { status: statuses.SUCCESS });

        let matchingOrderLine;

        if (quantity) {
          matchingOrderLine = data.lines.find(line => line.sku === sku);
          if (!matchingOrderLine) {
            console.error("No matching SKU was found.");
            return;
          }

          const {
            productName,
            id,
            unitPrice: { price },
            variantName,
          } = matchingOrderLine;

          const product = {
            name: productName,
            id,
            price,
            variant: variantName,
          };

          if (previousQuantity > quantity) {
            pushToDataLayer({
              event: "removeFromCart",
              ecommerce: {
                currencyCode: userConfiguration.contextCurrency,
                remove: {
                  products: [
                    {
                      ...product,
                      quantity: quantity
                        ? previousQuantity - quantity
                        : quantity,
                    },
                  ],
                },
              },
            });
          } else {
            pushToDataLayer({
              event: "addToCart",
              ecommerce: {
                currencyCode: userConfiguration.contextCurrency,
                add: {
                  products: [
                    { ...product, quantity: quantity - previousQuantity },
                  ],
                },
              },
            });
          }
        } else {
          matchingOrderLine = getters.basketLines.find(
            line => line.sku === sku,
          );
          if (!matchingOrderLine) throw Error("No matching SKU was found.");

          const {
            productName,
            id,
            unitPrice: { price },
            variantName,
          } = matchingOrderLine;

          pushToDataLayer({
            event: "removeFromCart",
            ecommerce: {
              currencyCode: userConfiguration.contextCurrency,
              remove: {
                products: [
                  {
                    name: productName,
                    id,
                    price,
                    variant: variantName,
                    quantity: previousQuantity,
                  },
                ],
              },
            },
          });
        }

        commit("SET_BASKET_LINES", { basketLines: data.lines });
        commit("SET_SHOULD_ACCOUNT_FOR_PRINTED_NAMES", {
          value: data.containsPrintedName,
        });
        commit("SET_TOTAL_PRICE", { totalPrice: data.totalPrice });

        const { productName, sizeChart, variantName } = matchingOrderLine;

        dispatch(
          "alerts/OPEN_ALERT",
          {
            data: {
              productName: `${productName} - ${variantName} (${sizeChart[
                userConfiguration.contextSizeChart
              ] || sizeChart["da"]})`,
              quantity,
            },
            key: quantity
              ? "SuccessMessage.ProductQuantityChange"
              : "SuccessMessage.ProductRemoved",
            type: alertTypes.SUCCESS,
          },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_BASKET_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_CUSTOMER_FOR_ADDING_TO_BASKET({ commit }, payload) {
    commit("SET_CUSTOMER_FOR_ADDING_TO_BASKET", {
      customerId: payload.customerId,
    });
  },
  UPDATE_DELIVERY_DATE_OF_BASKET_LINES(
    { commit, dispatch, rootGetters },
    payload,
  ) {
    commit("SET_BASKET_STATUS", { status: statuses.SAVING });

    const userId = rootGetters["authentication/userId"];

    const requests = payload.deliveries.map(({ value, variantId }) =>
      axios.post(
        `${endpoints.ECOMMERCE}/basket-management/users/${userId}/deliverydate`,
        {
          variantId,
          requestedDeliveryDate: value || null,
        },
      ),
    );

    return axios
      .all(requests)
      .then(() => {
        commit("SET_BASKET_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_BASKET");
      })
      .catch(error => {
        commit("SET_BASKET_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_DELIVERY_DATE_OF_ORDER({ commit, dispatch, rootGetters }, payload) {
    commit("SET_BASKET_STATUS", { status: statuses.SAVING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .post(
        `${endpoints.ECOMMERCE}/basket-management/users/${userId}/deliverydatefororder`,
        {
          requestedDeliveryDate: payload.requestedDeliveryDate || null,
        },
      )
      .then(() => {
        commit("SET_BASKET_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_BASKET");
      })
      .catch(error => {
        commit("SET_BASKET_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_DISCOUNT_OF_BASKET_LINES({ commit, dispatch, rootGetters }, payload) {
    commit("SET_BASKET_STATUS", { status: statuses.SAVING });

    const userId = rootGetters["authentication/userId"];

    const requests = payload.discounts.map(({ value, variantId }) =>
      axios.post(
        `${endpoints.ECOMMERCE}/basket-management/users/${userId}/linediscount`,
        { variantId, discountPercent: value || 0 },
      ),
    );

    return axios
      .all(requests)
      .then(() => {
        commit("SET_BASKET_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_BASKET");
      })
      .catch(error => {
        commit("SET_BASKET_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_BASKET_ERRORS(state, payload) {
    state.errors = payload.errors;
  },
  SET_BASKET_ID(state, payload) {
    state.id = payload.id;
  },
  SET_BASKET_LINES(state, payload) {
    state.basketLines = payload.basketLines;
  },
  SET_BASKET_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_CUSTOMER_FOR_ADDING_TO_BASKET(state, payload) {
    state.selectedCustomerForAddingToBasket = payload.customerId;
  },
  SET_NAMES_FOR_PRINT(state, payload) {
    state.namesForPrint = payload.names;
  },
  SET_SHOULD_ACCOUNT_FOR_PRINTED_NAMES(state, payload) {
    state.shouldAccountForPrintedNames = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_BASKET_ERRORS(state, payload) {
    state.isModalForBasketErrorsOpen = payload.value;
  },
  SET_TOTAL_PRICE(state, payload) {
    state.totalPrice = payload.totalPrice;
  },
  SET_CURRENCY(state, payload) {
    state.currency = payload.currency;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
